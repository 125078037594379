<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 10:25:44
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 23:20:48
-->
<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import http from '@/util/http'
export default {
  name: 'app',
  mounted() {
    this.initFontSize();
    this.category();
    this.portal_category();
    // this.dcategory();
  },
  methods: {
    async category(){
      window.localStorage.removeItem('category');
      var { data } = await http.get('/api/portal_category/index.html');
      var arr = [];
      for (let key in data) {
        data[key].children = [];
        for(let index in data[key].child){
          data[key].children.push(data[key].child[index])
        }
        data[key].show = false;
        arr.push(data[key]);
      }
      this.$store.commit("SET_DCATEGORY", data[203].children);
      this.$store.commit("SET_SCATEGORY", [data[173]]);
      
      var cate = [];
      arr.forEach(e => {
        cate.push(e);
      })
      let order = [2,72,41,106,35,1,3, 207,208];
      cate = cate.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      cate.splice(0, 2)
      this.$store.commit("SET_CATEGORY", cate);
    },
    async portal_category(){
      var { data } = await http.get('/api/portal_category/index.html', {type: 2});
      var arr = [];
      for (let key in data) {
        data[key].children = [];
        for(let index in data[key].child){
          data[key].children.push(data[key].child[index])
        }
        data[key].show = false;
        arr.push(data[key]);
      }
      let order = [90,12,57,59,95];
      arr = arr.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id)
      })
      this.$store.commit("SET_PCATEGORY", arr);
    },
    // async dcategory(){
    //   var { data } = await http.get('/api/portal_category/index.html', {type: 1});
    //   console.log(data);
    //   // this.$store.commit("SET_PCATEGORY", arr);
    // },
    initFontSize() {
      ; (function (doc, win) {
        var docEI = doc.documentElement,
          resizeEvt =
            'orientationchange' in window ? 'orientataionchange' : 'resize',
          recalc = function () {
            var clientWidth = docEI.clientWidth
            if (clientWidth <= 1366) {
              clientWidth = 1366
            }
            if (!clientWidth) return
            docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
          }

        if (!doc.addEventListener) return
        win.addEventListener(resizeEvt, recalc, false)
        doc.addEventListener('DOMContentLoaded', recalc, false)
      })(document, window)
    },
  }
}
</script>

<style lang="scss">
.diaAtrel{
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
    .contents{
      height: 180px;
      text-align: center;
      line-height: 180px;
      border-bottom: 1px solid #E6E6E6;
      font-size: 16px;
    }
    .footers{
      display: flex;
      align-items: center;
      height: 60px;
      line-height: 60px;
      text-align: center;
      .item{
        font-size: 18px;
        flex: 1;
        cursor: pointer;
        &:first-child{
          border-right: 1px solid #E6E6E6;
        }
        &.q{
          color: #666666;
        }
        &.c{
          color: #FF6A29;
        }
      }
    }
  }
}
</style>
