<template>
  <el-form-item class="captcha" label="验证码：" :prop="prop" :rules="rules">
    <span v-if="!disabled" class="get-captcha text-main" @click="getCode">获取验证码</span>
    <span v-else class="get-captcha text-main disabled">{{second}}s</span>
    <el-input v-model="value" placeholder="请输入手机验证码"></el-input>
  </el-form-item>
</template>
<script>
import { sendSms } from '@/api/user'
import md5 from 'js-md5';
export default {
  data() {
    return {
      timer: null,
      second: 30,
      disabled: false,
      value: '',
      rules: [
        { required: true, message: '该项不能为空', trigger: 'blur' },
        {
          pattern: /^[\d|\w]{4,6}$/,//手机号验证码4位
          message: '格式不正确',
          trigger: ['blur']
        }
      ]
    }
  },
  props: {
    code: String,
    mobile: String,
    type: {
      type: String,
      required: true //值为 1’：’登录验证码’, ‘2’：’身份验证码’, ‘3’：’登录确认验证码’,’4’：’登录异常验证码’, ‘5’：’用户注册验证码’, ‘6’：’修改密码验证码’, ‘7：’信息变更验证码’
    },
    prop: {
      type: String,
      default: 'smsCode'
    }
  },
  watch: {
    value(val) {
      this.$emit("update:code", val)
    },
    code(val) {
      this.value = val
    }
  },
  methods: {
    getCode() {
      if (!this.mobile) {
        this.$message.warning('请输入手机号')
        return
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$message.warning('手机号格式不正确')
        return
      }
      this.sendSms()
    },
    sendSms() {
      sendSms({
        mobile: this.mobile,
        type: this.type
      }).then(res => {
        this.second = 60;
        this.disabled = true
        this.timer = setInterval(() => {

          this.second--;
          if (this.second <= 0) {
            this.disabled = false
            clearInterval(this.timer)
          }
        }, 1000)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item.captcha {
  .el-input {
    display: block;
    width: auto;
    margin-right: 100px;
  }
  .get-captcha {
    float: right;
    height: 56px;
    line-height: 54px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }
}
</style>