import Vue from 'vue';

const moment = require("moment");

Vue.filter("dateFormat", (val, fmt = "YYYY/MM/DD") => {
  return val ? moment(val, "YYYY-MM-DD HH:mm:ss").format(fmt) : '';
});
Vue.filter("mobileFormat", (val) => {
  return val.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
});
