<template>
  <div class="h-upload-images">
    <el-upload :accept="accept" :limit="limit" action="/upload" list-type="picture-card" :file-list="fileList" :http-request="upload" :on-change="handleChange" :on-exceed="handleExceed" :on-success="handleSuccess" :on-error="handleError" :before-upload="beforeUpload" :before-remove="beforeRemove" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
      <div class="uploader-image" v-loading="file.loading" slot="file" slot-scope="{file}" style="height:100%">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { webuploaderss } from '@/api/user'
export default {
  data() {
    return {
      fileList: [],//对象数组
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  props: {
    defaultImages: Array,//对象数组
    fileSizeLimit: {
      type: Number,
      default: 10 //M
    },
    fileSizeLimitLabel: String,
    accept: {
      type: String,
      default: 'image/*'
    },
    limit: {
      type: Number,
      default: 5
    }
  },
  watch: {
    defaultImages(val, oldVal) {
      //只有获取到数据的时候才会执行
      this.initImages()
    }
  },
  created() {
    this.initImages();
  },
  methods: {
    initImages() {
      if (this.defaultImages) {
        this.fileList = this.defaultImages.map(item => ({ url: item }))
      }
    },
    async upload(params) {
      let fd = new FormData()
      fd.append('file', params.file);
      fd.append('uniqid', this.$store.getters.uniqid);
      webuploaderss(fd, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => {
          let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
          //调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
          //params.onProgress({ percent: '' })
        }
      }).then(res => {
        params.onSuccess(res.data);
      }).catch(err => {
      })

    },
    beforeUpload(file) {
      const fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt2M = file.size / 1024 / 1024 < this.fileSizeLimit;
      if (!isImage) {
        this.$message.error("只能上传图片");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能大于" + this.fileSizeLimit + 'M');
        return false;
      }
    },
    beforeRemove(file, fileList) {

    },
    handleExceed(file, fileList) {

    },
    handleError(err, file, fileList) {

    },
    handleChange(file, fileList) {
      if (file) {
        if (file.url && file.url.includes('blob')) {
          file.loading = true
        } else {
          file.loading = false
        }
      }
    },
    handleSuccess(data, file, fileList) {
      if (data) {
        file.url = data.filepath;
        this.fileList = fileList
        this.$emit('imagesUpdate', this.fileList);
      }
    },
    handleRemove(file) {
      let index = this.fileList.findIndex(item => item.uid === file.uid)
      this.fileList.splice(index, 1)
      this.$emit('imagesUpdate', this.fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    clear() {
      this.fileList = []
    }
  }

}
</script>
<style scoped lang="scss">
.h-upload-images {
  ::v-deep .el-upload--picture-card,
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    position: relative;
    width: 90px;
    height: 90px;
    background: url("~@/assets/images/upload-bg.png") no-repeat 0 0;
    background-size: 100% 100%;
    cursor: pointer;
    &:hover {
      border-color: $mainColor;
    }
    &.is-success {
      background: none;
    }
  }
}
</style>
