<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 10:25:44
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-04 10:52:34
-->
<template>
  <div class="h-foot">
    <div class="wrap">
      <div class="top clearfix">
        <div class="left">
          <div class="logo">
            <img src="../assets/images/sheep-logo.png" style="width:100px;" alt="">
          </div>
          <div class="info" style="margin-left:128px;">
            <dl>
              <dt>北京金小央教育科技有限公司</dt>
              <dd>联系方式：18211059862</dd>
              <dd>公司地址：北京市通州区新华南路64号4层4110</dd>
            </dl>
          </div>
        </div>
        <div class="right">
          <div class="qrcode">
            <div class="img-wrap">
              <img src="../assets/images/qrcode.png" style="width:80px;height:80px;" alt="">
            </div>
            <div class="label">扫码下载金小央APP</div>
            <div class="label">复旦金融考研交流QQ群：386213852</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <a href="https://beian.miit.gov.cn" target="_blank"> 备案号：京ICP备20017428号</a>
      </div>
    </div>
  </div>
</template>
<script>
import { getSystem } from '@/api/common'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.h-foot {
  height: 240px;
  background: #25262d;
  position: relative;
  z-index: 1;
  .wrap {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 100%;
    background: #25262d;
    color: #cecfd8;
    font-size: 14px;
    .top {
      width: $contentWidth;
      max-width: 100%;
      margin: 0 auto;
      padding: 20px 0;
      .left {
        float: left;
        .logo {
          float: left;
          img {
            width: 99px;
          }
        }
        .info {
          margin-left: 128px;
          margin-top: 19px;
          dt {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 8px;
          }
          dd {
            margin-top: 7px;
          }
        }
      }
      .right {
        float: right;
        .qrcode {
          padding-top: 10px;
          text-align: center;
          .img-wrap {
            line-height: 0;
            img {
              width: 80px;
              height: 80px;
              background: #eee;
            }
          }
          .label {
            margin-top: 7px;
          }
        }
      }
    }
    .bottom {
      height: 40px;
      padding-top: 10px;
      border-top: 1px solid #3b3c46;
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
}
</style>