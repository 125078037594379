/*
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 16:04:07
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-18 11:27:56
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
Vue.use(VueRouter);



const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/indexs',
    children: [
      {
        path: 'indexs',
        name: 'indexs',
        component: () => import('@/views/indexs'),
        meta: {
          title: '首页',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'live',
        name: 'live',
        component: () => import('@/views/live'),
        meta: {
          title: '课程',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'liveDetail',
        name: 'liveDetail',
        component: () => import('@/views/live/detail.vue'),
        meta: {
          title: '课程',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'video',
        name: 'video',
        component: () => import('@/views/live/video.vue'),
        meta: {
          title: '课程',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'down',
        name: 'down',
        component: () => import('@/views/new/down.vue'),
        meta: {
          title: '资料下载',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'new',
        name: 'new',
        component: () => import('@/views/new'),
        meta: {
          title: '资讯',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'student',
        name: 'student',
        component: () => import('@/views/student'),
        meta: {
          title: '硕士招生',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'newSearch',
        name: 'newSearch',
        component: () => import('@/views/new-search'),
        meta: {
          title: '咨询',
          searchHead: true,
          showfoot: true
        }
      },
      {
        path: 'newDetail',
        name: 'newDetail',
        component: () => import('@/views/new/detail.vue'),
        meta: {
          title: '详情页',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'wxapp',
        name: 'wxapp',
        component: () => import('@/views/wxapp'),
        meta: {
          title: '考研小程序',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'advisory',
        name: 'advisory',
        component: () => import('@/views/advisory'),
        meta: {
          title: '报名咨询',
          newHead: true,
          showfoot: true
        }
      },
      
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: {
          title: '首页',
          newHead: true,
          showfoot: true
        }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/search'),
        meta: {
          title: '搜索'
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/user'),
        meta: {
          title: '个人中心',
          needLogin: true,
          showfoot: true
        }
      },
      {
        path: 'user/edit',
        name: 'userEdit',
        component: () => import('@/views/user/edit'),
        meta: {
          title: '编辑资料',
          needLogin: true,
          showfoot: true
        }
      },
      {
        path: 'exam/:id',
        name: 'exam',
        component: () => import('@/views/exam'),
        meta: {
          title: '答题',
          needLogin: true
        }
      },
      {
        path: 'question/:id',
        name: 'questionDetail',
        component: () => import('@/views/question/detail'),
        meta: {
          title: '题目',
          needLogin: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router
