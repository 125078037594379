<template>
  <div class="dialog-main edit-mobile">
    <h4>修改手机号</h4>
    <h5>当前绑定手机号：<strong>{{$store.getters.mobile}}</strong></h5>
    <el-form class="h-form" ref="form" :model="form" :rules="rules" label-position="left" label-width="70px">
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <captcha-form-item type="7" prop="sms_code" :code.sync="form.sms_code" :mobile="form.mobile"></captcha-form-item>
      <div class="btn-group">
        <a href="javascript:;" @click="close">取消</a>
        <el-button v-loading="loading" class="submit" type="primary" @click="submit">保存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import captchaFormItem from '@/components/captchaFormItem'
import { changeMobile } from '@/api/user'
export default {
  components: {
    captchaFormItem
  },
  data() {
    return {
      loading: false,
      form: {
        sms_code: '',
        mobile: ''
      },
      rules: {
        mobile: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '格式不正确',
            trigger: ['blur']
          }
        ],
        sms_code: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{4-6}$/,
            message: '格式不正确',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          changeMobile({
            uniqid: this.$store.getters.uniqid,
            ...this.form
          }).then((res) => {
            this.loading = false
            this.$message.success(res.msg)
            this.$emit('editMobileSuccess', this.form.mobile)
          }).catch(err => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-mobile {
  padding: 30px;
  height: 440px;
  h4 {
    margin-top: 18px;
    font-size: 24px;
  }
  h5 {
    margin-top: 15px;
    font-size: 16px;
    strong {
      font-size: 24px;
      vertical-align: text-bottom;
    }
  }
  .h-form {
    padding-top: 28px;
    height: 50px;
    .btn-group {
      button {
        width: 240px;
        float: right;
      }
      a {
        float: left;
        width: 100px;
        padding-left: 25px;
        line-height: 50px;
        font-size: 16px;
        color: $mainColor;
      }
    }
  }
}
</style>