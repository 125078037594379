import { setToken } from '@/util/auth'
export default {
  SET_TOKEN(state, val) {
    state.token = val;
    setToken(val)
  },
  SET_USER_INFO(state, val) {
    state.userInfo = val
  },
  SET_FROM_ROUTE(state, val) {
    state.fromRoute = {
      name: val.name,
      query: val.query,
      params: val.params,
    }
  },
  SET_CATEGORY(state, val){
    state.category = val
  },
  SET_PCATEGORY(state, val){
    state.pcategory = val
  },
  SET_DCATEGORY(state, val){
    state.dcategory = val
  },
  SET_SCATEGORY(state, val){
    state.scategory = val
  }
};