import { render, staticRenderFns } from "./hHead2.vue?vue&type=template&id=6137bf8b&scoped=true"
import script from "./hHead2.vue?vue&type=script&lang=js"
export * from "./hHead2.vue?vue&type=script&lang=js"
import style0 from "./hHead2.vue?vue&type=style&index=0&id=6137bf8b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6137bf8b",
  null
  
)

export default component.exports