<template>
  <div class="wx-login">
    <div class="qrcode-wrapper">
      <div class="qrcode" id="wxqrcode">
        <wx-login :appid="data.appid" :scope="data.scope" :redirect_uri="data.redirect_uri" :href="data.href" theme="black">
        </wx-login>
      </div>
      <div class="tip">请使用微信扫码登录</div>
    </div>
  </div>
</template>
<script>
import wxLogin from 'vue-wxlogin';
export default {
  components: {
    wxLogin
  },
  data() {
    return {
      checked: true,
      data: {
        appid: 'wx610fe6fca1576bd1',
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent('http://tiku.jinxiaozhi.com/#/getCode'),
        href: ''
        //href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUgewogYm9yZGVyOiBub25lOwptYXJnaW4tdG9wOjA7CiB3aWR0aDogMTAwJTsKIGhlaWdodDogMTAwJTsKfQouaW1wb3dlckJveCAuc3RhdHVzewogZGlzcGxheTogbm9uZQp9'
      }
    }
  },
  created() {
    this.data.redirect_uri = encodeURIComponent('http://tiku.jinxiaozhi.com/#' + this.$route.fullPath)
    let _this = this;
    let content = ".impowerBox .title{display:none}.impowerBox .status.status_browser{display:none}.impowerBox .qrcode{border:none;margin-top:0;width:100%;height:100%}.impowerBox .status{display:none}";
    let blob = new Blob([content], { type: "text/css;charset=utf-8" });
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
      _this.data.href = this.result
    };
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.wx-login {
  padding-top: 35px;
  text-align: center;
  .qrcode-wrapper {
    .tip {
      margin-top: 2px;
      font-size: 14px;
      line-height: 1;
      color: $textSubColor;
    }
  }
  .qrcode {
    ::v-deep iframe {
      width: 230px;
      height: 230px;
    }
  }
}
</style>