<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 16:04:07
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 20:43:18
-->
<template>
  <div class="page-layout" :class="className">
    <template v-if="$route.meta.newHead">
      <h-head2></h-head2>
    </template>
    <template v-else-if="$route.meta.searchHead">
      <s-head></s-head>
    </template>
    <template v-else>
      <h-head></h-head>
    </template>
    
    <div class="h-body">
      <router-view />
    </div>
    <h-foot v-if="$route.meta.showfoot"></h-foot>
  </div>
</template>
<script>
import hHead from '@/components/hHead'
import hHead2 from '@/components/hHead2'
import sHead from '@/components/sHead'
import hFoot from '@/components/hFoot'
export default {
  components: {
    hHead,
    hHead2,
    sHead,
    hFoot
  },
  data(){
    return{
      className: ''
    }
  },
  watch: {
    '$route'(newValue, oldValue) {
      if(newValue.name == 'video'){
        this.className = 'pageBlack';
      }else{
        this.className = 'pageWhite';
      }
    },
  },
  mounted() {
    if(this.$route.name == 'video'){
      this.className = 'pageBlack';
    }else{
      this.className = 'pageWhite';
    }
  },
  
}
</script>
<style lang="scss" scoped>
.pageWhite{
  background: #f7f8fa;
}
.pageBlack{
  background-color: #000;
}
.page-layout {
  padding-top: $headHeight;
  .h-body {
    min-height: calc(100vh - 300px);
  }
}
</style>