/*
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 10:25:44
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 19:30:38
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getToken } from '@/util/auth'
import * as actions from './actions';
import mutations from './mutations';
import getters from './getters'

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    token: getToken(),
    fromRoute: {},
    userInfo: {},
    category: [],
    pcategory: [],
    dcategory: [],
    scategory: []
  },
  getters,
  mutations,
  actions,
  plugins: [createPersistedState({ storage: window.localStorage })]
});
export default store;