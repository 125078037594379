<template>
  <div class="sms-login">
    <el-form class="h-form" ref="form" :model="form" :rules="rules" label-position="left" label-width="70px">
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号" @keyup.enter.native="submit"></el-input>
      </el-form-item>
      <captcha-form-item type="1" :code.sync="form.smsCode" :mobile="form.mobile"></captcha-form-item>
      <div class="btn-group">
        <el-button v-loading="loading" class="submit" type="primary" @click="submit">登录</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import captchaFormItem from '@/components/captchaFormItem'
import { loginBySms } from '@/api/user'
export default {
  components: {
    captchaFormItem
  },
  data() {
    return {
      loading: false,
      form: {
        mobile: '',
        smsCode: '',
        mac: 'unknown',
        is_pc: true
      },
      rules: {
        mobile: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '格式不正确',
            trigger: ['blur']
          }
        ],
        smsCode: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{4}$/,
            message: '格式不正确',
            trigger: ['blur']
          }
        ],
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          loginBySms(this.form).then(async (res) => {
            this.loading = false
            let { status, uniqid } = res.data;
            this.$store.commit('SET_TOKEN', uniqid);
            this.$message.success(res.msg)
            await this.$store.dispatch('getUserInfo')
            this.$emit('smsLoginSuccess')
          }).catch(err => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.sms-login {
  padding-top: 30px;
}
</style>