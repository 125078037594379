<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 15:18:20
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-25 18:02:54
-->
<template>
  <div class="h-head">
    <div class="wrap clearfix">
      <div class="left">
        <router-link class="logo" :to="{path:'/'}">
          <img src="../assets/images/logo.png" alt="">
        </router-link>
        <div class="line"></div>
        <div class="deSearch">搜索</div>
      </div>
      <div class="right">
        <div class="action-group action-search">
          
        </div>
        
        <div class="action-group action-user">
          <span v-if="$store.getters.uniqid">
            <router-link :to="{name:'user'}" class="link">
              <el-image :src="$store.getters.avatar" alt="">
                <img slot="error" class="image-slot" :src="require('@/assets/images/default-avatar.png')" alt="">
              </el-image>
              <span>
                {{$store.getters.user_nickname}},
              </span>
            </router-link>

            <a href="javascript:;" @click="logout">退出登录</a>
          </span>
          <a v-else href="javascript:;" @click="showLogin">
            <img src="../assets/images/default-avatar.png" alt="默认头像">
            登录
          </a>
        </div>
      </div>
    </div>

    <el-dialog custom-class="h-dialog" width="4rem" title="" :show-close="false" :visible.sync="loginVisible" append-to-body>
      <login @loginSuccess="loginSuccess"></login>
    </el-dialog>

    <el-dialog custom-class="h-dialog" width="6rem" title="" :show-close="false" :visible.sync="uploadVisible" append-to-body>
      <upload-answer @cancel="uploadVisible=false" @uploadSuccess="uploadSuccess"></upload-answer>
    </el-dialog>

    <el-dialog custom-class="h-dialog" width="4rem" title="" :show-close="false" :visible.sync="mobileVisible" append-to-body>
      <edit-mobile @close="mobileVisible=false" @editMobileSuccess="editMobileSuccess"></edit-mobile>
    </el-dialog>
  </div>
</template>
<script>
import uploadAnswer from '@/components/uploadAnswer'
import editMobile from '@/components/editMobile'
import login from './login'
import { loginByWebsiteWechat } from '@/api/user'
export default {
  components: {
    login,
    uploadAnswer,
    editMobile
  },
  data() {
    return {
      loginVisible: false,
      uploadVisible: false,
      mobileVisible: false
    }
  },
  watch: {
    code(val) {
      if (val) {
        this.wxLogin()
      }
    }
  },
  computed: {
    code() {
      return this.$route.query.code
    }
  },
  created() {
    this.$root.Bus.$on('showLogin', () => {
      this.loginVisible = true
    })

    this.wxLogin()
  },
  methods: {
    wxLogin() {
      let code = this.$route.query.code;
      if (!code) return
      loginByWebsiteWechat({
        code
      }).then(res => {
        let { mobile, uniqid } = res.userinfo || {}
        //去绑手机号
        this.$store.commit('SET_TOKEN', uniqid);
        this.$store.dispatch('getUserInfo')
        this.loginVisible = false
        this.$message.success(res.msg)
        if (!mobile) {
          this.mobileVisible = true
        }
      })
      //及时清掉code,之后不再弹
      let query = Object.assign({}, this.$route.query);
      delete query.code
      let route = {
        name: this.$route.name,
        params: this.$route.params,
        query
      }
      this.$router.replace(route)
    },
    editMobileSuccess() {
      this.mobileVisible = false
      this.$store.dispatch('getUserInfo')
    },
    showLogin() {
      this.loginVisible = true
    },
    logout() {
      this.$store.commit('SET_USER_INFO', {})
      this.$store.commit('SET_TOKEN', '')
      if(this.$route.name == 'live'){
        this.$router.push({name: 'indexs'});
        return;
      }
      location.reload()
    },
    loginSuccess() {
      this.loginVisible = false
      location.reload()
    },
    upload() {
      if (!this.$store.getters.uniqid) {
        this.loginVisible = true
        return
      }
      this.uploadVisible = true
    },
    uploadSuccess() {
      this.uploadVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.h-head {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100px;
  padding: 12px 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  .left {
    float: left;
    display: flex;
    align-items: center;
    .deSearch{
      font-size: 24px;
      color: #666666;
    }
    .line{
      width: 2px;
      height: 40px;
      background-color: #E6E6E6;
      margin: 0 20px;
    }
  }
  .right {
    float: right;
  }
  .wrap {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    .logo {
      line-height: 0;
      img {
        height: 50px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding-top: 2px;
  }
  .action-group {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    img {
      vertical-align: middle;
    }
    &.action-search{
      margin-right: 20px;
      .search{
        width: 300px;
        height: 48px;
        border: 1px solid #E6E6E6;
        input{
          width: 300px;
          height: 48px;
        }
      }
      .icon-search{
        width: 48px;
        height: 48px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    &.action-user {
      img,
      .el-image {
        width: 32px;
        height: 32px;
        margin-right: 5px;
        vertical-align: middle;
        border-radius: 50%;
      }
      a {
        color: $textLightColor;
        text-decoration: underline;
      }
      .link {
        text-decoration: none;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
</style>