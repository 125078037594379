<template>
  <div class="dialog-main upload-answer">
    <div class="dialog-head">
      上传题目
    </div>
    <div class="input-container">
      <el-input v-model="form.content" placeholder="请输入内容" type="textarea"></el-input>
      <upload-images @imagesUpdate="imagesUpdate"></upload-images>
    </div>
    <div class="h-btn2-group">
      <button class="btn" @click="cancel">取消</button>
      <button v-loading="loading" class="btn" @click="sure">确认</button>
    </div>
  </div>
</template>
<script>
import uploadImages from '@/components/uploadImages'
import { feedBack } from '@/api/user'
export default {
  components: {
    uploadImages
  },
  data() {
    return {
      form: {
        photos: [],
        content: ''
      },
      loading: false
    }
  },
  methods: {
    imagesUpdate(val) {
      this.form.photos = val.map(item => item.url)
    },
    cancel() {
      this.$emit('cancel')
    },
    sure() {
      if (!this.form.content) {
        this.$message.warning('请输入内容')
        return
      }
      this.loading = true
      feedBack({
        uniqid: this.$store.getters.uniqid,
        type: 3,// 1：意见反馈 2：题目纠错 3：上传题目
        content: this.form.content,
        photos: JSON.stringify(this.form.photos)
      }).then(res => {
        this.loading = false
        this.$message.success(res.msg)
        this.$emit('uploadSuccess')
      }).catch(err => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-answer {
  width: 600px;
  height: 600px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 30px;
  .input-container {
    height: 380px;
    background: #f7f7fa;
    border-radius: 10px;
    padding: 18px 20px 0;
    ::v-deep .el-textarea__inner {
      appearance: none;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      height: 248px;
    }
  }
  .h-btn2-group {
    margin-top: 48px;
  }
}
</style>