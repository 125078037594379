<template>
  <div class="dialog-main module-login">
    <div class="login-head">
      <div class="h-tab-select">
        <div class="tab" style="width:50%" v-for="item in typeOptions" :key="item.name" :class="{active:item.name===activeType}" @click="selectType(item.name)">
          <a href="javascript:;">{{item.label}}</a>
        </div>
      </div>
    </div>
    <div class="login-body">
      <wx-login v-if="activeType==='wx'" :type="activeType"></wx-login>
      <sms-login v-else :type="activeType" @smsLoginSuccess="loginSuccess"></sms-login>
    </div>
  </div>
</template>
<script>
import wxLogin from './wxLogin'
import smsLogin from './smsLogin'
export default {
  components: {
    wxLogin,
    smsLogin
  },
  data() {
    return {
      activeType: 'sms',
      typeOptions: [
        {
          name: 'wx',
          label: '微信登录'
        },
        {
          name: 'sms',
          label: '短信登录'
        }
      ]
    }
  },
  methods: {
    selectType(name) {
      this.activeType = name
    },
    loginSuccess() {
      this.$emit('loginSuccess')
    }
  }
}
</script>
<style lang="scss" scoped>
.module-login {
  .login-head {
    border-bottom: 1px solid $borderColor;
  }
  .login-body {
    padding: 0 30px;
    height: 340px;
  }
}
</style>