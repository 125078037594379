import request from './request.js';
const http = {
  get(url, data, config) {
    return request(url, {
      params: data
    }, config);
  },
  post(url, data, config) {
    return request.post(url, data, config);
  },
  put(url, data, config) {
    return request.put(url, data, config);
  },
  delete(url, data, config) {
    return request.delete(url, data, config);
  }
};
export default http;