import http from '@/util/http'
export const sendSms = (data) => http.post('/api/user/sendSms', data);
export const loginBySms = (data) => http.post('/api/user/loginBySms', data);
export const userInfo = (data) => http.get('/api/user/userInfo', data);
export const webuploader = (data) => http.post('/api/user/webuploader', data);
export const webuploaderss = (data, config) => http.post('/api/user/webuploaderss', data, config);
export const modify = (data) => http.post('/api/user/modify', data);
export const changeMobile = (data) => http.post('/api/user/changeMobile', data);
export const feedBack = (data) => http.post('/api/user/feedBack', data);
export const getFavList = (data) => http.post('/api/user/getFavList', data);
export const loginByWebsiteWechat = (data) => http.post('/api/user/loginByWebsiteWechat', data);
export const getFavorite = (data) => http.post('/api/course/getList', data);