/*
 * @Author: 569353634@qq.com
 * @Date: 2022-11-15 10:00:23
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-07 11:21:15
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI, { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import '@/style/element-variables.scss'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video


import '@/style/common.scss';//需覆盖以上的样式

import "./util/filters"


import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import moment from 'moment'//导入文件 
Vue.prototype.$moment = moment;//赋值使用

import { formatEnterStr } from "@/util/common"
Vue.prototype.$formatEnterStr = formatEnterStr;
Vue.config.productionTip = false;

const Bus = new Vue()


router.beforeEach((to, from, next) => {
  // determine whether the user has logged in
  if (to.meta.needLogin) {
    if (!store.getters.uniqid) {
      Bus.$emit('showLogin')
      if (from.path === '/' || from.meta.needLogin) {
        router.replace({
          name: 'home'
        })
      }
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  data: {
    Bus
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app');


