/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
export const getUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}
export function getFileSuffix(str) {
  return str.substring(str.lastIndexOf("."))
}
export function getFileName(str) {
  return str.substring(str.lastIndexOf('/') + 1);
}
export function isMobile() {
  var ua = navigator.userAgent,
    // isWindowsPhone = /(?:Windows Phone)/.test(ua),
    // isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    // isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  // isPc = !isPhone && !isAndroid && !isSymbian;
  return (isAndroid || isPhone)
}
export function formatEnterStr(str) {
  str = str.replace(/\r\n/g, "<br>")
  str = str.replace(/\n/g, "<br>");
  str = str.replace(/\r/g, "<br>");
  return str
}
export function getDpi() {
  let arr = [];
  if (window.screen.deviceXDPI) {
    arr[0] = window.screen.deviceXDPI;
    arr[1] = window.screen.deviceYDPI;
  } else {
    let tmpNode = document.createElement("DIV");//in:英寸
    tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arr[0] = parseInt(tmpNode.offsetWidth);
    arr[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arr;
}