import axios from 'axios'
import { Message } from 'element-ui'
import { baseURL } from './config'
import { getToken } from '@/util/auth'
import router from '../../router';
import store from '../../store';
const request = axios.create({
  baseURL,
  timeout: 30 * 1000, // Timeout
});
request.interceptors.request.use(function (config) {

  let token = getToken()
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  const { data } = response
  if (data.status < 1) {
    Message({
      message: data.msg || '失败',
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject('error')
  }
  return data;
}, function (error) {
  Message({
    message: error.data.message,
    type: 'error',
    duration: 2 * 1000
  })
  return Promise.reject(error)
});

export default request;